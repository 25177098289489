import React from 'react';
import useRuntimeRenderable from '@prospectus/common/hooks/useRuntimeRenderable';
import {
  FIRST_LEVEL_RESULTS,
} from '@utils/search';

import FirstLevelResult from './FirstLevelResult';

export const placeholderCourse = {
  activeCourseRun: {
    key: 'course',
    marketingUrl: '/',
    type: 'Verified and Audit',
  },
  cardType: 'course',
  image: {
    src: './',
  },
  inProspectus: true,
  objectID: 'skeleton',
  owners: [{
    key: 'skeleton',
    logoImageUrl: './',
    name: 'skeleton',
  }],
  position: 0,
  prospectusPath: './',
  queryID: 'skeleton',
  recentEnrollmentCount: 0,
  title: 'skeleton',
  topics: [{
    topic: 'skeleton',
  }],
  uuid: 'skeleton',
};

const placeholderProgram = {
  authoringOrganizations: {
    key: 'skeleton',
    logoImageUrl: '/',
    name: 'skeleton',
  },
  cardImageUrl: './',
  cardType: 'program',
  courses: [
    {
      title: 'program',
    },
  ],
  inProspectus: true,
  marketingPath: './',
  queryID: 'skeleton',
  recentEnrollmentCount: 0,
  title: 'skeleton',
  topics: [{
    topic: 'skeleton',
  }],
  type: 'skeleton',
  uuid: 'skeleton',
};

function SearchResultsSkeleton() {
  const runtimeRenderable = useRuntimeRenderable();
  return (
    <>
      <FirstLevelResult
        type="program"
        count={1}
        productList={runtimeRenderable ? [1, 2, 3, 4].map((idx) => ({ ...placeholderProgram, uuid: `${placeholderProgram.uuid}-${idx}` })) : []}
        query=" "
        isSearchStalled
        indexName="program"
        className="mt-2.5 mt-md-4 pt-2 mw-lg px-xl-0"
        resultsLevel={FIRST_LEVEL_RESULTS}
      />
      <div className="mt-2.5 mt-md-4 pt-2">
        <FirstLevelResult
          type={runtimeRenderable ? 'course' : 'program'} // Avoiding hydration mismatch error
          count={1}
          productList={runtimeRenderable ? [1, 2, 3, 4].map((idx) => ({ ...placeholderCourse, uuid: `${placeholderCourse.uuid}-${idx}` })) : []}
          query=" "
          isSearchStalled
          indexName={runtimeRenderable ? 'course' : 'program'}
          className="mt-2.5 mt-md-4 pt-2 mw-lg px-xl-0"
          resultsLevel={FIRST_LEVEL_RESULTS}
        />
      </div>
    </>
  );
}

export default SearchResultsSkeleton;

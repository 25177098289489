import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@edx/paragon';
import track from 'react-tracking';

import { programFields, courseFields } from '@utils/searchResults';
import useGeotargeting from '@prospectus/common/hooks/useGeotargeting';
import { filterCardListModuleByCountryRestriction } from '@utils/location';
import PartnerFilters from './components/PartnerFilters';
import ProgramFilters from './components/ProgramFilters';
import DefaultCardResults from './components/DefaultCardResults';
import SubjectFilters from './components/SubjectFilters';

function PreQueryPage({
  promotedProducts,
  promotedExecEd,
  promotedBootcamp,
  promotedMasters,
  promotedBachelors,
  courseLocationRestrictionMap,
  programLocationRestrictionMap,
  webview,
}) {
  const { geotargetingEnabled } = useGeotargeting();

  const getProductsToShow = productList => {
    if (geotargetingEnabled) {
      return filterCardListModuleByCountryRestriction(
        productList,
        courseLocationRestrictionMap,
        programLocationRestrictionMap,
      );
    }
    return productList;
  };

  const getProductsFromObjectToShow = productObject => {
    if (geotargetingEnabled
      && typeof productObject === 'object'
      && productObject != null) {
      return Object.keys(productObject).reduce((accumulator, key) => {
        accumulator[key] = getProductsToShow(productObject[key]);
        return accumulator;
      }, {});
    }
    return productObject;
  };

  return (
    <Container className="mw-lg px-xl-0 search-landing">
      <SubjectFilters />
      <DefaultCardResults
        promotedExecEd={getProductsToShow(promotedExecEd)}
        promotedBootcamp={getProductsToShow(promotedBootcamp)}
        promotedMasters={getProductsToShow(promotedMasters)}
        promotedBachelors={getProductsToShow(promotedBachelors)}
        promotedProducts={getProductsFromObjectToShow(promotedProducts)}
        webview={webview}
      />
      <ProgramFilters />
      <PartnerFilters />
    </Container>
  );
}

const RestrictionDictionaryListPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    states: PropTypes.arrayOf(PropTypes.string),
  }),
);

const LocationRestrictionPropTypes = PropTypes.shape({
  allowlist: RestrictionDictionaryListPropTypes,
  blocklist: RestrictionDictionaryListPropTypes,
});

PreQueryPage.propTypes = {
  promotedExecEd: PropTypes.arrayOf(PropTypes.shape(courseFields)),
  promotedBootcamp: PropTypes.arrayOf(PropTypes.shape(courseFields)),
  promotedMasters: PropTypes.arrayOf(PropTypes.shape(programFields)),
  promotedBachelors: PropTypes.arrayOf(PropTypes.shape(programFields)),
  promotedProducts: PropTypes.shape({
    mostPopularProducts: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(programFields),
        PropTypes.shape(courseFields),
      ]),
    ),
    newProducts: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(programFields),
        PropTypes.shape(courseFields),
      ]),
    ),
    trendingProducts: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(programFields),
        PropTypes.shape(courseFields),
      ]),
    ),
  }).isRequired,
  courseLocationRestrictionMap: LocationRestrictionPropTypes.isRequired,
  programLocationRestrictionMap: LocationRestrictionPropTypes.isRequired,
  webview: PropTypes.bool,
};

PreQueryPage.defaultProps = {
  promotedExecEd: [],
  promotedBootcamp: [],
  promotedMasters: [],
  promotedBachelors: [],
  webview: false,
};

export default track(() => ({
  component: 'search-pre-query',
}))(PreQueryPage);

import './styles.scss';
import React, { useContext } from 'react';
import { CardGrid, Card } from '@edx/paragon';
import { injectIntl, defineMessages } from 'gatsby-plugin-react-intl';
import { intlShape } from '@prospectus/common/types/intlShape';
import { WebviewContext } from '@utils/context';
import { addSearchDeviceParam } from '@utils/search';
import ProspectusRouterLink from '@prospectus/common/ui/ProspectusRouterLink';

const messages = defineMessages({
  'prospectus.search.schools-filters.header': {
    id: 'prospectus.search.schools-filters.header',
    description: 'Label for cards that filter the search results by school',
    defaultMessage: 'Filter by popular schools and partners',
  },
});

const subjectsByPartnerEN = [
  {
    school: 'Harvard University',
    subjects: [
      'Data Analysis & Statistics',
      'Humanities',
      'Computer Science',
      'Biology & Life Sciences',
      'Art & Culture',
    ],
  },
  {
    school: 'Massachusetts Institute of Technology',
    subjects: [
      'Business & Management',
      'Engineering',
      'Data Analysis & Statistics',
      'Economics & Finance',
      'Computer Science',
    ],
  },
];

const subjectsByPartnerES = [
  {
    school: 'Harvard University',
    subjects: [
      'Análisis de datos',
      'Humanidades',
      'Informática',
      'Biología y ciencias humanas',
      'Arte y cultura',
    ],
  },
  {
    school: 'Massachusetts Institute of Technology',
    subjects: [
      'Negocios y administración',
      'Ingeniería',
      'Análisis de datos',
      'Economía y finanzas',
      'Informática',
    ],
  },
];

function PartnerFilters({
  intl,
}) {
  const subjectsByPartner = intl.locale === 'es' ? subjectsByPartnerES : subjectsByPartnerEN;
  const pathPrefix = intl.locale === 'es' ? '/es' : '';

  const getParamString = (partner, subject) => new URLSearchParams({
    partner,
    subject,
  }).toString();

  const webview = useContext(WebviewContext);

  return (
    <div className="school-partner-filters mt-5 mb-4.5">
      <h1 className="h1 mb-3.5 mb-sm-4 school-partner-filters__heading">{intl.formatMessage(messages['prospectus.search.schools-filters.header'])}</h1>
      {subjectsByPartner.map((partner) => (
        <div key={partner.school} className="school-partner-filters__school">
          <h2 className="h2 mb-3 mb-sm-3.5 school-partner-filters__school-title">{partner.school}</h2>
          <CardGrid
            className="school-partner-filters__grid"
            columnSizes={{
              xs: 6,
              md: 4,
              lg: 2.5,
            }}
          >
            {partner.subjects.map((subject) => {
              const path = `${pathPrefix}/search?${getParamString(partner.school, subject)}`;
              return (
                <Card key={subject} className="school-partner-filters__card">
                  <ProspectusRouterLink
                    className="text-decoration-none w-100 h-100 school-partner-filters__card-link"
                    href={addSearchDeviceParam(path, webview)}
                    analytics={{
                      eventName: 'edx.bi.search-landing.schools.topic-card.clicked',
                      linkProps: {
                        partner: partner.school,
                        subject,
                      },
                    }}
                  >
                    <h4 className="h4 text-gray-700 mb-0 school-partner-filters__card-subject">
                      {subject}
                    </h4>
                  </ProspectusRouterLink>
                </Card>
              );
            })}
          </CardGrid>
        </div>
      ))}
    </div>
  );
}

PartnerFilters.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PartnerFilters);

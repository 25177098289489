import './styles.scss';
import React, { useContext } from 'react';
import { Image } from '@edx/paragon';
import { injectIntl, defineMessages } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { WebviewContext } from '@utils/context';
import { addSearchDeviceParam } from '@utils/search';

import ArchitectureImage from '@static/images/experiments/searchPreQuery/architecture-filter.png';
import BusinessImage from '@static/images/experiments/searchPreQuery/business-filter.png';
import CompSciImage from '@static/images/experiments/searchPreQuery/compsci-filter.png';
import DataSciImage from '@static/images/experiments/searchPreQuery/datasci-filter.png';
import EducationImage from '@static/images/experiments/searchPreQuery/education-filter.png';
import EngineeringImage from '@static/images/experiments/searchPreQuery/engineering.png';
import LawImage from '@static/images/experiments/searchPreQuery/law-filter.png';
import MathImage from '@static/images/experiments/searchPreQuery/math-filter.png';
import MedicineImage from '@static/images/experiments/searchPreQuery/medicine-filter.png';
import NutritionImage from '@static/images/experiments/searchPreQuery/nutrition-filter.png';

import ProspectusRouterLink from '@prospectus/common/ui/ProspectusRouterLink';

const messages = defineMessages({
  'prospectus.search.landing.subject.header': {
    id: 'prospectus.search.landing.subject.header',
    defaultMessage: 'Filter by popular subjects',
    description: 'Header for a list of filters that allow a user to apply a search on different subjects',
  },
});

const subjectMapEN = [
  {
    label: 'Data Science',
    query: 'Data Analysis & Statistics',
    src: DataSciImage,
  },
  {
    label: 'Computer Science',
    query: 'Computer Science',
    src: CompSciImage,
  },
  {
    label: 'Math',
    query: 'Math',
    src: MathImage,
  },
  {
    label: 'Architecture',
    query: 'Architecture',
    src: ArchitectureImage,
  },
  {
    label: 'Education & Teacher Training',
    query: 'Education & Teacher Training',
    src: EducationImage,
  },
  {
    label: 'Business & Management',
    query: 'Business & Management',
    src: BusinessImage,
  },
  {
    label: 'Engineering',
    query: 'Engineering',
    src: EngineeringImage,
  },
  {
    label: 'Food & Nutrition',
    query: 'Food & Nutrition',
    src: NutritionImage,
  },
  {
    label: 'Law',
    query: 'Law',
    src: LawImage,
  },
  {
    label: 'Medicine',
    query: 'Medicine',
    src: MedicineImage,
  },
];

const subjectMapES = [
  {
    label: 'Análisis de datos',
    query: 'Análisis de datos',
    src: DataSciImage,
  },
  {
    label: 'Informática',
    query: 'Informática',
    src: CompSciImage,
  },
  {
    label: 'Matemáticas',
    query: 'Matemáticas',
    src: MathImage,
  },
  {
    label: 'Arquitectura',
    query: 'Arquitectura',
    src: ArchitectureImage,
  },
  {
    label: 'Formación docente',
    query: 'Formación docente',
    src: EducationImage,
  },
  {
    label: 'Negocios y administración',
    query: 'Negocios y administración',
    src: BusinessImage,
  },
  {
    label: 'Ingeniería',
    query: 'Ingeniería',
    src: EngineeringImage,
  },
  {
    label: 'Alimentación y nutrición',
    query: 'Alimentación y nutrición',
    src: NutritionImage,
  },
  {
    label: 'Derecho',
    query: 'Derecho',
    src: LawImage,
  },
  {
    label: 'Medicina',
    query: 'Medicina',
    src: MedicineImage,
  },
];

function SubjectFilters({
  intl,
}) {
  const webview = useContext(WebviewContext);
  const subjectMap = intl.locale === 'es' ? subjectMapES : subjectMapEN;
  const halfMap = Math.ceil(subjectMap.length / 2);

  const pathPrefix = intl.locale === 'es' ? '/es' : '';

  const getSubjectFilter = (subject, index) => (
    <div key={subject.label}>
      <ProspectusRouterLink
        className="d-flex align-items-center px-0 mr-4"
        id={`subject-filter-${index}`}
        href={addSearchDeviceParam(`${pathPrefix}/search?${(new URLSearchParams({ subject: subject.query })).toString()}`, webview)}
        analytics={{
          eventName: 'edx.bi.user.subject.filtered',
          linkProps: {
            label: subject.label,
          },
        }}
      >
        <Image
          alt={subject.alt}
          src={subject.src}
          className="subject-filter__icon mr-3"
        />
        <h4 className="h4 mb-0 subject-filter__text">{subject.label}</h4>
      </ProspectusRouterLink>
    </div>
  );

  return (
    <div className="subject-filters pb-4">
      <h1 className="h1 my-4 my-sm-4.5 pb-sm-1">{intl.formatMessage(messages['prospectus.search.landing.subject.header'])}</h1>
      <div className="subject-filters__filters mb-4 pb-2">
        {/* deliberately split over two rows */}
        <div className="d-flex flex-nowrap justify-content-between mb-4.5 mx-0 pb-1">
          {subjectMap.slice(0, halfMap).map((subject, index) => getSubjectFilter(subject, index))}
        </div>
        <div className="d-flex flex-nowrap justify-content-between mx-0">
          {subjectMap.slice(-halfMap).map(subject => getSubjectFilter(subject))}
        </div>
      </div>
    </div>
  );
}

SubjectFilters.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SubjectFilters);

import React from 'react';
import propTypes from 'prop-types';
import { Script } from 'gatsby';

function UserZoomSurvey({ id, sid }) {
  return (
    <Script>{`
      _uzactions = window._uzactions || [];
      _uzactions.push(['_setID', '${id}']);
      _uzactions.push(['_setSID', '${sid}']);
      _uzactions.push(['_start']);
      (function() {
        var uz = document.createElement('script'); uz.type = 'text/javascript'; uz.async = true; uz.charset = 'utf-8';
        uz.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn5.userzoom.com/trueintent/js/uz_til_us.js?cuid=0FD6276E6ECDE41180C90050569444FB';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(uz, s);
      })();
  `}</Script>
  );
}

UserZoomSurvey.propTypes = {
  id: propTypes.string,
  sid: propTypes.string,
};

UserZoomSurvey.defaultProps = {
  id: '',
  sid: '',
};

export default UserZoomSurvey;

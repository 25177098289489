import './styles.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import SEOPage from '@prospectus/seo/ui/SEOPage';

import Layout from '@prospectus/common/ui/Layout';
import SearchPage from '@prospectus/search-page/components/SearchPage';

import messages from '@prospectus/search-page/components/messages';
import { WebviewContext } from '@utils/context';
import useSearchParams from '@prospectus/common/hooks/useSearchParams';
import { submitPageViewAnalytics } from '@utils/analytics';

// Layout, webview context, and SEO wrapper
// around the search page template.  This allows
// for less rerendering as the page state changes
// since only the components in the SearchPage component
// will rerender, as opposed to all the layout and seo components
// as well.
// This also provides a central point for handling
// pageviews and navigation related code without
// it being overwhelmed by the mechanics of searching
// and parsing data from Algolia in the SearchPage template.
function SearchPageWrapper(props) {
  const {
    location,
    location: {
      pathname,
    },
    pageContext: {
      webview,
    },
    intl,
  } = props;

  const { searchParams, rewriteBrowserUrlWithoutExtraneousParams } = useSearchParams();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    rewriteBrowserUrlWithoutExtraneousParams(['geotargeting']);
  }, []);

  useEffect(() => {
    if (isMounted) {
      // When managing page view submissions, the initial client render
      // will submit a page view in gatsby-browser.js.
      // In order to not duplicate pageviews, we will only fire this
      // submitPageViewAnalytics() method when we navigate via the
      // router.
      submitPageViewAnalytics();
    } else {
      setIsMounted(true);
    }
  }, [searchParams]);

  return (
    <Layout
      webview={webview}
      removeTrailingCompanyName
      mainClassname="new-search"
      title={intl.formatMessage(messages['prospectus.search.page.new.title'])}
      location={location}
    >
      <WebviewContext.Provider value={webview}>
        <SearchPage {...props} />
        <SEOPage
          meta={({
            title: intl.formatMessage(messages['prospectus.search.page.new.title']),
            description: intl.formatMessage(messages['prospectus.search.page.new.description']),
            pathname,
            robots: 'noindex, nofollow',
          })}
        />
      </WebviewContext.Provider>
    </Layout>
  );
}

SearchPageWrapper.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    webview: PropTypes.bool,
  }).isRequired,
  intl: intlShape.isRequired,
};

export default track(({ location }) => ({
  page: 'search',
  mobile: false,
  url: location.href,
}))(injectIntl(SearchPageWrapper));

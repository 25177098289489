import React, { useContext, useRef } from 'react';
import { Link } from 'gatsby-link';
import { connect } from 'react-redux';
import track, { useTracking } from 'react-tracking';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { withI18nPrefix } from '@utils/i18n';
import { submitEvent } from '@utils/analytics';
import { getWebviewUrl } from '@utils/program';
import { WebviewContext } from '@utils/context';

function BaseProspectusRouterLink({
  href,
  children,
  className,
  id,
  linkProps,
  inProspectus,
  userLanguage,
  analytics,
  intl,
  allowFragment,
  linkRef: suppliedLinkRef,
}) {
  const linkRef = suppliedLinkRef || useRef(null);
  const webview = useContext(WebviewContext);

  const tracking = useTracking();

  const getComponentTrackingArgs = () => {
    const { eventName, linkProps: analyticsLinkProps = {} } = analytics;
    const componentProps = {
      category: 'navigation',
      link: linkRef.current.getAttribute('href'),
      ...analyticsLinkProps,
    };
    return [eventName, componentProps];
  };

  const getUrl = () => {
    // Allow the link to link to another section of the page.
    // The allowFragment property allows slowly introducing this to
    // the component without it affecting every usage.
    if (allowFragment && href.startsWith('#')) {
      return href;
    }

    if (inProspectus) {
      /**
       * If no userLanguage is set pass the language code for the page
       * to ensure links on /es pages point to /es pages
       */
      const languageCode = userLanguage || intl.locale;

      return withI18nPrefix(href, languageCode);
    }

    return href;
  };

  const urlProp = { href: webview && inProspectus ? getWebviewUrl(href) : getUrl() };
  return (
    <Link
      className={className}
      id={id}
      to={urlProp.href}
      {...linkProps}
      ref={linkRef}
      onClick={() => {
        const [eventName, properties] = getComponentTrackingArgs();
        submitEvent(tracking, properties, eventName);
      }}
    >
      {children}
    </Link>
  );
}

const mapStateToProps = store => ({
  userLanguage: store.userLanguage,
});

BaseProspectusRouterLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  analytics: PropTypes.shape({
    eventName: PropTypes.string.isRequired,
    linkProps: PropTypes.shape(),
  }).isRequired,
  linkProps: PropTypes.shape(),
  userLanguage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  inProspectus: PropTypes.bool,
  intl: intlShape.isRequired,
  tracking: PropTypes.shape(),
  allowFragment: PropTypes.bool,
  linkRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]),
};

BaseProspectusRouterLink.defaultProps = {
  allowFragment: false,
  className: '',
  id: '',
  linkProps: {},
  userLanguage: false,
  inProspectus: true,
  tracking: {},
  linkRef: undefined,
};

const ProspectusLink = connect(mapStateToProps)(BaseProspectusRouterLink);
export default (track()(injectIntl(ProspectusLink)));

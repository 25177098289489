import './styles.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumb,
  Container,
  Icon,
} from '@edx/paragon';
import classNames from 'classnames';

import { Home, ChevronLeft } from '@edx/paragon/icons';
import { injectIntl, defineMessages } from 'gatsby-plugin-react-intl';
import ProspectusRouterLink from '@prospectus/common/ui/ProspectusRouterLink';
import { intlShape } from '@prospectus/common/types/intlShape';
import {
  SEARCH_PATH,
  getIntlSearchPath,
  PRE_QUERY_BREADCRUMBS,
  FIRST_LEVEL_BREADCRUMBS,
  SECOND_LEVEL_BREADCRUMBS,
} from '@utils/search';
import useSearchParams from '@prospectus/common/hooks/useSearchParams';
import useRuntimeRenderable from '@prospectus/common/hooks/useRuntimeRenderable';

const messages = defineMessages({
  'prospectus.search.breadcrumb.link.home.label': {
    id: 'prospectus.search.breadcrumb.link.home.label',
    description: 'Label for the link which takes back to the search landing page.',
    defaultMessage: 'Search home',
  },
  'prospectus.search.breadcrumb.active.label': {
    id: 'prospectus.search.breadcrumb.active.label',
    description: 'Label for the active search page.',
    defaultMessage: 'Search',
  },
  'prospectus.search.breadcrumb.link.back.label': {
    id: 'prospectus.search.breadcrumb.link.back.label',
    description: 'Label for the link which takes back to the search filters page.',
    defaultMessage: 'Back to search',
  },
});

function SearchBreadcrumb({
  breadcrumbLevel, intl: { locale }, intl, className, isLoading,
}) {
  const runtimeRenderable = useRuntimeRenderable();
  const { clearSearch, removeSearchParam } = useSearchParams();
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const searchPath = () => getIntlSearchPath(SEARCH_PATH, locale);

  const breadcrumbProps = {
    [PRE_QUERY_BREADCRUMBS]: () => ({
      links: [],
      activeLabel: intl.formatMessage(messages['prospectus.search.breadcrumb.link.home.label']),
    }),
    [FIRST_LEVEL_BREADCRUMBS]: () => ({
      links: [{
        label: intl.formatMessage(messages['prospectus.search.breadcrumb.link.home.label']),
        href: `/${searchPath()}`,
      }],
      activeLabel: intl.formatMessage(messages['prospectus.search.breadcrumb.active.label']),
      clickHandler: (e) => {
        e.preventDefault();
        clearSearch();
      },
    }),
    [SECOND_LEVEL_BREADCRUMBS]: () => ({
      links: [{
        label: intl.formatMessage(messages['prospectus.search.breadcrumb.link.back.label']),
        href: `/${searchPath()}`,
      }],
      clickHandler: (e) => {
        e.preventDefault();
        removeSearchParam('tab');
      },
    }),
  };

  const isBack = breadcrumbLevel === SECOND_LEVEL_BREADCRUMBS;
  const breadcrumbIconClass = breadcrumbLevel === PRE_QUERY_BREADCRUMBS ? 'text-gray-500' : 'text-primary-500';

  return (
    <Container size="lg" className={`search-breadcrumb d-flex align-items-center text-primary-500 ${className}`}>
      {breadcrumbLevel !== PRE_QUERY_BREADCRUMBS && runtimeRenderable ? (
        <ProspectusRouterLink
          href={searchPath()}
          analytics={{
            eventName: 'edx.bi.link.search-routing.clicked',
          }}
        >
          <Icon src={isMounted && isBack ? ChevronLeft : Home} className={`search-breadcrumb-icon ${breadcrumbIconClass}`} />
        </ProspectusRouterLink>
      ) : (
        <Icon src={isMounted && isBack ? ChevronLeft : Home} className={`search-breadcrumb-icon ${breadcrumbIconClass}`} />
      )}
      <div className={classNames({ 'skeleton skeleton-pulse px-1': isLoading })}>
        {runtimeRenderable ? (
          <Breadcrumb {...breadcrumbProps[breadcrumbLevel]()} />
        ) : null}
      </div>
    </Container>
  );
}

export default injectIntl(SearchBreadcrumb);

SearchBreadcrumb.propTypes = {
  breadcrumbLevel: PropTypes.string.isRequired,
  className: PropTypes.string,
  intl: intlShape.isRequired,
  isLoading: PropTypes.bool,
};

SearchBreadcrumb.defaultProps = {
  className: null,
  isLoading: true,
};

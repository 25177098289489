import Cookies from 'js-cookie';
import apiClient from '@data/apiClient';
import { getEnterpriseLink } from '@utils/edxLinks';
import {
  LEARNER_ENTERPRISE_DEFAULT,
  LEARNER_ENTERPRISE_FETCH_ERROR,
  LEARNER_ENTERPRISE_FETCH_SUCCESS,
  LEARNER_ENTERPRISE_USER_NOT_FOUND,
} from '@data/constants/actionTypes/enterprise';

export const learnerEnterpriseFetchError = error => ({
  type: LEARNER_ENTERPRISE_FETCH_ERROR,
  error,
});

export const learnerEnterpriseFetchSuccess = value => ({
  type: LEARNER_ENTERPRISE_FETCH_SUCCESS,
  value,
});

export const learnerEnterpriseUserNotFound = value => ({
  type: LEARNER_ENTERPRISE_USER_NOT_FOUND,
  value,
});

export const learnerEnterpriseDefault = () => ({
  type: LEARNER_ENTERPRISE_DEFAULT,
});

export const enterpriseUser = () => (dispatch) => {
  // Calling API only when the user is logged in and we have user_id for the logged in user
  // ajs_user_id is the id for logged in user and being used to query and check if the user
  // has an enterprise learner.
  if (!!Cookies.get('edxloggedin') && !!Cookies.get('ajs_user_id')) {
    const ENTERPRISE_ENDPOINT = getEnterpriseLink(process.env.GATSBY_ENTERPRISE_LEARNER_API);
    const ajsUserId = decodeURIComponent(Cookies.get('ajs_user_id')).replace(/"/g, '');
    if (Number(ajsUserId)) {
      const ENTERPRISE_LEARNER_DETAILS_LINK = `${ENTERPRISE_ENDPOINT}?user_id=${ajsUserId}`;
      apiClient
        .get(ENTERPRISE_LEARNER_DETAILS_LINK, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.ok || response.status === 200) {
            const isEnterpriseUser = !!response.data.count;
            return dispatch(learnerEnterpriseFetchSuccess(isEnterpriseUser));
          }
          const error = new Error(response.statusText);
          error.response = response;

          return dispatch(learnerEnterpriseFetchError(error));
        })
        .catch((error) => dispatch(learnerEnterpriseFetchError(error)));
    } else {
      return dispatch(() => dispatch(learnerEnterpriseUserNotFound(false)));
    }
  }
  return dispatch(() => dispatch(learnerEnterpriseDefault()));
};

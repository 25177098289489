import { useState, useRef, useEffect } from 'react';

const extractVariables = (defaults, loaded) => {
  if (loaded.current) { return {}; }
  const { experimentVariables } = window;
  const targetVars = Object.keys(defaults);
  const extracted = {};
  const varsSource = experimentVariables && typeof experimentVariables === 'object' ? experimentVariables : {};

  targetVars.forEach((variableName) => {
    if (varsSource[variableName] === undefined) {
      extracted[variableName] = defaults[variableName];
    } else {
      extracted[variableName] = varsSource[variableName];
    }
  });

  return extracted;
};

const useExperimentVariables = (defaults) => {
  if (typeof window === 'undefined') { return defaults; }
  const experimentVarsAreAvailable = window.experimentVariables && typeof window.experimentVariables === 'object';
  const loaded = useRef(false);
  const [experimentVariables, setExperimentVariables] = useState(extractVariables(defaults, loaded));
  loaded.current = experimentVarsAreAvailable;

  useEffect(() => { // this effect is necessary in case window.experimentVariables is injected AFTER the main runtime.
    if (loaded.current) { return; }// avoid updating state, since if true means that vars are avail prior to js runtime.
    setExperimentVariables(extractVariables(defaults, loaded));
  }, []);

  return experimentVariables;
};

export default useExperimentVariables;

import React from 'react';
import PropTypes from 'prop-types';
import { Check } from '@edx/paragon/icons';
import { addQueryParams } from '@utils/search';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';

function FilterProgramCard({
  slug,
  imgUrl,
  title,
  description,
  checklistItems,
}) {
  return (
    <div className="program-card">
      <ProspectusLink
      /* For now here but will be moved into a function once the ticket for multiple uses of this is created */
        href={addQueryParams(slug, { external_link: true })}
        className="text-white text-decoration-none"
        analytics={{
          eventName: 'edx.bi.user.search-landing.filters.program.card.click',
          linkProps: {
            category: 'Search Filter',
          },
        }}
      >

        <div className="program-card__image-container">
          <img className="program-card__img" src={imgUrl} alt="card header" />
        </div>

        <div className="program-card__title">
          {title}
        </div>

        <div className="program-card__description">
          {description}
        </div>

        <div className="program-card__checklist">
          <ul className="program-card__ul">
            {checklistItems.map(item => (
              <li className="program-card__li" key={item}>
                <span className="checkicon">
                  <Check className="checkicon__svg" color="#03C7E8" />
                </span>
                <span className="pl-1">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </ProspectusLink>
    </div>
  );
}

FilterProgramCard.propTypes = {
  slug: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.instanceOf(Array).isRequired,
  checklistItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FilterProgramCard;

import './styles.scss';
import React, { useContext } from 'react';
import { defineMessages, injectIntl } from 'gatsby-plugin-react-intl';
import FilterProgramCard from '@prospectus/search-page/components/FilterProgramCard';
import { WebviewContext } from '@utils/context';
import { addSearchDeviceParam } from '@utils/search';
import { intlShape } from '@prospectus/common/types/intlShape';

import imageMasters from '@static/images/experiments/searchPreQuery/imageMasters.png';
import imageMicroMasters from '@static/images/experiments/searchPreQuery/imageMicroMasters.png';
import imageMicroBachelors from '@static/images/experiments/searchPreQuery/imageMicroBachelors.png';
import imageProfessional from '@static/images/experiments/searchPreQuery/imageProfessional.png';
import imageXSeries from '@static/images/experiments/searchPreQuery/imageXSeries.png';

const messages = defineMessages({
  'prospectus.search-landing.filters.programs.heading': {
    id: 'prospectus.search-landing.filters.programs.heading',
    defaultMessage: 'Filter by programs',
    description: 'Heading for search filters by program section',
  },
  'prospectus.search-landing.filters.programs.subheading': {
    id: 'prospectus.search-landing.filters.programs.subheading',
    defaultMessage: 'Get a degree or certificate with edX online',
    description: 'Subheading for Label search filters by program section',
  },
  'prospectus.search-landing.filters.programs.masters.title': {
    id: 'prospectus.search-landing.filters.programs.masters.title',
    defaultMessage: 'Master\'s Program',
    description: 'Label for a heading on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.masters.description': {
    id: 'prospectus.search-landing.filters.programs.masters.description',
    defaultMessage: 'Earn a <markup>full master\'s</markup> degree from top-ranked universities',
    description: 'Label for a description on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.masters.tick.one': {
    id: 'prospectus.search-landing.filters.programs.masters.tick.one',
    defaultMessage: 'Graduate-level',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.masters.tick.two': {
    id: 'prospectus.search-landing.filters.programs.masters.tick.two',
    defaultMessage: 'Master\'s Degree',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.masters.tick.three': {
    id: 'prospectus.search-landing.filters.programs.masters.tick.three',
    defaultMessage: 'Affordable & flexible',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.micromasters.title': {
    id: 'prospectus.search-landing.filters.programs.micromasters.title',
    defaultMessage: 'MicroMaster\'s Program®',
    description: 'Label for a heading on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.micromasters.description': {
    id: 'prospectus.search-landing.filters.programs.micromasters.description',
    defaultMessage: '<markup>Start your master\'s</markup> degree and advance your career',
    description: 'Label for a description on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.micromasters.tick.one': {
    id: 'prospectus.search-landing.filters.programs.micromasters.tick.one',
    defaultMessage: 'Graduate-level',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.micromasters.tick.two': {
    id: 'prospectus.search-landing.filters.programs.micromasters.tick.two',
    defaultMessage: 'Degree path',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.micromasters.tick.three': {
    id: 'prospectus.search-landing.filters.programs.micromasters.tick.three',
    defaultMessage: 'Affordable & flexible',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.microbachelors.title': {
    id: 'prospectus.search-landing.filters.programs.microbachelors.title',
    defaultMessage: 'MicroBachelor\'s Program®',
    description: 'Label for a heading on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.microbachelors.description': {
    id: 'prospectus.search-landing.filters.programs.microbachelors.description',
    defaultMessage: '<markup>Start your bachelor\'s</markup> degree',
    description: 'Label for a description on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.microbachelors.tick.one': {
    id: 'prospectus.search-landing.filters.programs.microbachelors.tick.one',
    defaultMessage: 'Undergraduate-level',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.microbachelors.tick.two': {
    id: 'prospectus.search-landing.filters.programs.microbachelors.tick.two',
    defaultMessage: 'Degree path',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.microbachelors.tick.three': {
    id: 'prospectus.search-landing.filters.programs.microbachelors.tick.three',
    defaultMessage: 'Career advancement',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.professional.title': {
    id: 'prospectus.search-landing.filters.programs.professional.title',
    defaultMessage: 'Professional Certificate',
    description: 'Label for a heading on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.professional.description': {
    id: 'prospectus.search-landing.filters.programs.professional.description',
    defaultMessage: 'Learn <markup>career-relevant</markup> skils and stand out at work',
    description: 'Label for a description on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.professional.tick.one': {
    id: 'prospectus.search-landing.filters.programs.professional.tick.one',
    defaultMessage: 'In-demand career skills',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.professional.tick.two': {
    id: 'prospectus.search-landing.filters.programs.professional.tick.two',
    defaultMessage: 'edX certificate',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.professional.tick.three': {
    id: 'prospectus.search-landing.filters.programs.professional.tick.three',
    defaultMessage: 'Career advancement',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.xseries.title': {
    id: 'prospectus.search-landing.filters.programs.xseries.title',
    defaultMessage: 'X-Series Program',
    description: 'Label for a heading on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.xseries.description': {
    id: 'prospectus.search-landing.filters.programs.xseries.description',
    defaultMessage: 'Explore <markup>popular topics</markup> and earn an edX certificate',
    description: 'Label for a description on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.xseries.tick.one': {
    id: 'prospectus.search-landing.filters.programs.xseries.tick.one',
    defaultMessage: 'Popular topics',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.xseries.tick.two': {
    id: 'prospectus.search-landing.filters.programs.xseries.tick.two',
    defaultMessage: 'edX certificate',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
  'prospectus.search-landing.filters.programs.xseries.tick.three': {
    id: 'prospectus.search-landing.filters.programs.xseries.tick.three',
    defaultMessage: 'Life-long learning',
    description: 'Label for one of the tick items on the card that redirects to master\'s landing',
  },
});

const newMessages = defineMessages({ // eslint-disable-line no-unused-vars
  'prospectus.search-landing.filters.programs.affordable': {
    id: 'prospectus.search-landing.filters.affordable',
    description: 'Describing course or program as financially affordable and flexible',
    defaultMessage: 'Affordable & flexible',
  },
  'prospectus.search-landing.filters.programs.graduate-level': {
    id: 'prospectus.search-landing.filters.programs.graduate-level',
    defaultMessage: 'Graduate-level',
    description: 'Describes the level of a graduate program',
  },
  'prospectus.search-landing.filters.programs.degree-path': {
    id: 'prospectus.search-landing.filters.programs.degree-path',
    defaultMessage: 'Degree path',
    description: 'Describes a program as a path to a degree',
  },
  'prospectus.search-landing.filters.programs.undergraduate-level': {
    id: 'prospectus.search-landing.filters.programs.undergraduate-level',
    defaultMessage: 'Undergraduate-level',
    description: 'Describes the level of an undergraduate program',
  },
  'prospectus.search-landing.filters.programs.career-advancement': {
    id: 'prospectus.search-landing.filters.programs.career-advancement',
    defaultMessage: 'Career advancement',
    description: 'Describes as a program as career advancement',
  },
  'prospectus.search-landing.filters.programs.in-demand-skills': {
    id: 'prospectus.search-landing.filters.programs.in-demand-skills',
    defaultMessage: 'In-demand career skills',
    description: 'Describes a program as having in-demand career skills',
  },
  'prospectus.search-landing.filters.programs.edx-certificate': {
    id: 'prospectus.search-landing.filters.programs.edx-certificate',
    defaultMessage: 'edX certificate',
    description: 'Describes that a program offers an edX certificate',
  },
  'prospectus.search-landing.filters.programs.popular-topics': {
    id: 'prospectus.search-landing.filters.programs.xseries.popular-topics',
    defaultMessage: 'Popular topics',
    description: 'Describes that a program covers popular topics',
  },
  'prospectus.search-landing.filters.programs.life-long-learning': {
    id: 'prospectus.search-landing.filters.programs.life-long-learning',
    defaultMessage: 'Life-long learning',
    description: 'Describes that a program offers life-long learning',
  },
});

const markup = { markup: str => <strong>{str}</strong> };

function ProgramFilters({ intl }) {
  const sectionHeading = intl.formatMessage(messages['prospectus.search-landing.filters.programs.heading']);
  const sectionSubheading = intl.formatMessage(messages['prospectus.search-landing.filters.programs.subheading']);

  const webview = useContext(WebviewContext);

  const programCardsIntl = [
    {
      slug: 'masters',
      imgUrl: imageMasters,
      title: intl.formatMessage(messages['prospectus.search-landing.filters.programs.masters.title']),
      description: intl.formatMessage(messages['prospectus.search-landing.filters.programs.masters.description'], markup),
      checklistItems: [
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.masters.tick.one']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.masters.tick.two']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.masters.tick.three']),
      ],
    },
    {
      slug: 'micromasters',
      imgUrl: imageMicroMasters,
      title: intl.formatMessage(messages['prospectus.search-landing.filters.programs.micromasters.title']),
      description: intl.formatMessage(messages['prospectus.search-landing.filters.programs.micromasters.description'], markup),
      checklistItems: [
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.micromasters.tick.one']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.micromasters.tick.two']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.micromasters.tick.three']),
      ],
    },
    {
      slug: 'microbachelors',
      imgUrl: imageMicroBachelors,
      title: intl.formatMessage(messages['prospectus.search-landing.filters.programs.microbachelors.title']),
      description: intl.formatMessage(messages['prospectus.search-landing.filters.programs.microbachelors.description'], markup),
      checklistItems: [
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.microbachelors.tick.one']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.microbachelors.tick.two']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.microbachelors.tick.three']),
      ],
    },
    {
      slug: 'professional-certificate',
      imgUrl: imageProfessional,
      title: intl.formatMessage(messages['prospectus.search-landing.filters.programs.professional.title']),
      description: intl.formatMessage(messages['prospectus.search-landing.filters.programs.professional.description'], markup),
      checklistItems: [
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.professional.tick.one']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.professional.tick.two']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.professional.tick.three']),
      ],
    },
    {
      slug: 'xseries',
      imgUrl: imageXSeries,
      title: intl.formatMessage(messages['prospectus.search-landing.filters.programs.xseries.title']),
      description: intl.formatMessage(messages['prospectus.search-landing.filters.programs.xseries.description'], markup),
      checklistItems: [
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.xseries.tick.one']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.xseries.tick.two']),
        intl.formatMessage(messages['prospectus.search-landing.filters.programs.xseries.tick.three']),
      ],
    },
  ];

  const renderCards = (programCards) => (
    <div className="cards-container">
      {/* WS-2419: Do not render Spanish masters card. Spanish masters is not useful. */}
      {programCards.filter(({ slug }) => (intl.locale === 'es' ? slug !== 'masters' : true)).map(item => (
        <FilterProgramCard
          key={item.title}
          slug={addSearchDeviceParam(item.slug, webview)}
          imgUrl={item.imgUrl}
          title={item.title}
          description={item.description}
          checklistItems={item.checklistItems}
        />
      ))}
    </div>
  );

  return (
    <div className="program-filters">
      <h1 className="mb-3.5 mb-sm-4 program-filters__heading">{sectionHeading}</h1>
      <h2 className="mb-3 mb-sm-3.5 program-filters__subheading">{sectionSubheading}</h2>
      {renderCards(programCardsIntl)}
    </div>
  );
}

ProgramFilters.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ProgramFilters);

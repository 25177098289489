import './styles.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-react-intl';

import { programFields, courseFields } from '@utils/searchResults';
import CardContainer from '@prospectus/common/ui/CardContainer';
import ProductPropTypes from '@prospectus/common/types/productPropTypes';
import { intlShape } from '@prospectus/common/types/intlShape';
import { isUserInUSA } from '@utils/location';
import messages from '@prospectus/search-page/components/messages';

function SearchSection({
  id,
  intl,
  productsList,
  shouldfirePreQuerySegmentEvent,
}) {
  return (
    <>
      <h2 className="h2 mb-3 mb-sm-3.5 default-results__subheading">
        {intl.formatMessage(messages[id])}
      </h2>
      <CardContainer
        shouldfirePreQuerySegmentEvent={shouldfirePreQuerySegmentEvent}
        productList={productsList}
        isScrollable
        additionalClassNames="mb-4"
        searchProps={{ hasDeviceParam: true }}
      />
    </>
  );
}

function DefaultCardResults({
  promotedProducts: {
    mostPopularProducts,
    newProducts,
    trendingProducts,
  },
  promotedExecEd,
  promotedBootcamp,
  promotedMasters,
  promotedBachelors,
  intl,
}) {
  // Hide bootcamps from non USA users
  const [isUserInUSAFromCookie, setIsUserInUSAFromCookie] = useState(false);
  useEffect(() => {
    setIsUserInUSAFromCookie(isUserInUSA());
  }, []);

  const shouldShowSection = (productList) => productList && productList.length > 0;

  const showExecutiveEducation = shouldShowSection(promotedExecEd);
  const showBootCamps = isUserInUSAFromCookie && shouldShowSection(promotedBootcamp);
  const showBachelors = shouldShowSection(promotedBachelors);
  const showMasters = shouldShowSection(promotedMasters);
  const showMostPopular = shouldShowSection(mostPopularProducts);
  const showNewProducts = shouldShowSection(newProducts);
  const showTrendingProducts = shouldShowSection(trendingProducts);

  return (
    <div className="default-results mt-4">
      <h1 className="h1 mb-3.5 mb-sm-4 default-results__heading">
        {intl.formatMessage(messages['prospectus.search.page.new.cards.title'])}
      </h1>
      {showMostPopular && (
        <SearchSection
          id="prospectus.search.page.new.cards.most-popular.title"
          productsList={mostPopularProducts}
          intl={intl}
        />
      )}
      {showExecutiveEducation && (
        <SearchSection
          id="prospectus.search.page.new.cards.exec-ed.title"
          productsList={promotedExecEd}
          intl={intl}
        />
      )}
      {showBootCamps && (
        <SearchSection
          id="prospectus.search.page.new.cards.bootcamp.title"
          productsList={promotedBootcamp}
          intl={intl}
        />
      )}
      {showMasters && (
        <SearchSection
          id="prospectus.search.page.new.cards.masters.title"
          productsList={promotedMasters}
          intl={intl}
        />
      )}
      {showBachelors && (
        <SearchSection
          id="prospectus.search.page.new.cards.bachelors.title"
          productsList={promotedBachelors}
          intl={intl}
        />
      )}
      {showNewProducts && (
        <SearchSection
          id="prospectus.search.page.new.cards.new.title"
          productsList={newProducts}
          intl={intl}
        />
      )}
      {showTrendingProducts && (
        <SearchSection
          id="prosectus.search.page.new.cards.trending.title"
          productsList={trendingProducts}
          intl={intl}
        />
      )}
    </div>
  );
}

SearchSection.propTypes = {
  id: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  shouldfirePreQuerySegmentEvent: PropTypes.bool,
  productsList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ProductPropTypes),
      PropTypes.shape(ProductPropTypes),
      PropTypes.shape({}),
    ]),
  ).isRequired,
};

DefaultCardResults.propTypes = {
  promotedExecEd: PropTypes.arrayOf(PropTypes.shape(courseFields)),
  promotedBootcamp: PropTypes.arrayOf(PropTypes.shape(courseFields)),
  promotedMasters: PropTypes.arrayOf(PropTypes.shape(programFields)),
  promotedBachelors: PropTypes.arrayOf(PropTypes.shape(programFields)),
  promotedProducts: PropTypes.shape({
    mostPopularProducts: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(programFields),
        PropTypes.shape(courseFields),
      ]),
    ),
    newProducts: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(programFields),
        PropTypes.shape(courseFields),
      ]),
    ),
    trendingProducts: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(programFields),
        PropTypes.shape(courseFields),
      ]),
    ),
  }).isRequired,
  intl: intlShape.isRequired,
};

SearchSection.defaultProps = {
  shouldfirePreQuerySegmentEvent: false,
};

DefaultCardResults.defaultProps = {
  promotedExecEd: [],
  promotedBootcamp: [],
  promotedMasters: [],
  promotedBachelors: [],
};

export default injectIntl(DefaultCardResults);
